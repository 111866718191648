import { MutableRefObject, useCallback, useRef } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { openLinkInNewTab } from "src/hooks/use-link-builder";
import { usePreventAnchorDefault } from "./use-prevent-anchor-default";

interface IReturn {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

export const useLeaveWikifolio = (ref: MutableRefObject<HTMLDivElement | null>): IReturn => {
    const refUrl = useRef<string | null>(null);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleOnAnchorClick = useCallback(
        (href: string) => {
            onOpen();
            refUrl.current = href;
        },
        [onOpen]
    );

    usePreventAnchorDefault({
        ref,
        onAnchorClick: handleOnAnchorClick,
    });

    const onConfirm = () => {
        onClose();
        if (refUrl.current) {
            openLinkInNewTab(refUrl.current);
        }
    };

    return { isOpen, onClose, onConfirm };
};
