import { HStack, Text } from "@chakra-ui/react";
import { FilledCircle } from "src/components/base/filled-circle";
import { useVotingStatus } from "src/components/common/voting/use-voting-status";

const VOTING_MODULE_REMAINING_DAYS_PART_TEST_ID = "voting-module-remaining-days-part";

export interface IVotingRemainingDaysProps {
    votingUntilDate: string;
    currentDate: string | undefined;
}

export const VotingRemainingDays = ({ votingUntilDate, currentDate }: IVotingRemainingDaysProps) => {
    const { getText, hasEnded } = useVotingStatus(votingUntilDate, currentDate);

    return (
        <HStack data-test-id={VOTING_MODULE_REMAINING_DAYS_PART_TEST_ID}>
            <FilledCircle size={2} color={hasEnded ? "gray.800" : "green.600"} />
            <Text display="inline-block">{getText()}</Text>
        </HStack>
    );
};
