import { useTranslation } from "next-i18next";
import { useAddWatchlistUnderlyingsMutation, useRemoveWatchlistUnderlyingsMutation } from "src/api/client/watchlist.api";
import { EnumToastStatus, useWfToast } from "src/hooks/use-wf-toast";
import { useUnderlyingFollowStore } from "src/stores/uderlying-follow-store";
import { useUserStore } from "src/stores/user-store";
import { useLinkBuilder } from "../use-link-builder";

const SUCCESS_MESSAGE_DURATION = 5000;
export const useUnderlyingFollowing = (underlyingIsin: string) => {
    const { t } = useTranslation("common");
    const { setIsFollowedUnderlying, canBeFollowedUnderlyingIsins, followedUnderlyingIsins } = useUnderlyingFollowStore();
    const toast = useWfToast();
    const { isLoggedIn } = useUserStore();
    const { openLoginModal } = useLinkBuilder();

    const isFollowed = followedUnderlyingIsins.includes(underlyingIsin);
    const canBeFollowed = canBeFollowedUnderlyingIsins.includes(underlyingIsin);

    const onError = () => {
        toast({ status: EnumToastStatus.Error, title: t("general-error") });
        setIsFollowedUnderlying(underlyingIsin, !isFollowed);
    };

    const onSuccess = (message: string) => () => {
        toast({
            duration: SUCCESS_MESSAGE_DURATION,
            status: EnumToastStatus.Success,
            title: message,
        });
    };

    const addWatchlistUnderlyingsMutation = useAddWatchlistUnderlyingsMutation({ onError, onSuccess: onSuccess(t("underlying-follow-success")) });
    const removeWatchlistUnderlyingsMutation = useRemoveWatchlistUnderlyingsMutation(underlyingIsin, {
        onError,
        onSuccess: onSuccess(t("underlying-unfollow-success")),
    });

    const toggleIsFollowed = () => {
        if (!isLoggedIn) {
            openLoginModal();
            return;
        }

        setIsFollowedUnderlying(underlyingIsin, !isFollowed);

        if (isFollowed) {
            removeWatchlistUnderlyingsMutation.mutate();
        } else {
            addWatchlistUnderlyingsMutation.mutate({ isin: underlyingIsin });
        }
    };

    return {
        canBeFollowed,
        isFollowed,
        toggleIsFollowed,
    };
};
