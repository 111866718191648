import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconFilledQuote: TIconComponent = props => (
    <Icon viewBox="0 0 48 48" {...props}>
        <path
            d="m6.3852 41c10.003-6.1946 15.005-14.149 15.005-23.863 0-3.0973-0.78369-5.5611-2.3511-7.3913-1.5674-1.8302-3.7776-2.7453-6.6306-2.7453-5.6356 0-8.4533 2.8509-8.4533 8.5528 0 1.971 0.86294 3.8188 2.5888 5.5435 1.7259 1.7246 2.5888 3.2909 2.5888 4.6988 0 1.3375-0.36983 2.6046-1.1095 3.8012s-2.7473 3.0445-6.023 5.5435l4.3852 5.8602zm24.61 0c10.003-6.1946 15.005-14.149 15.005-23.863 0-3.0973-0.78369-5.5611-2.3511-7.3913-1.5674-1.8302-3.7776-2.7453-6.6306-2.7453-5.6356 0-8.4533 2.8509-8.4533 8.5528 0 1.971 0.86294 3.8188 2.5888 5.5435s2.5888 3.2909 2.5888 4.6988c0 1.3375-0.36983 2.6046-1.1095 3.8012-0.73967 1.1967-2.7473 3.0445-6.023 5.5435l4.3852 5.8602z"
            fill="currentColor"
        />
    </Icon>
);
