import { MutableRefObject, useCallback, useEffect } from "react";

interface IPreventAnchorDefaultProps {
    ref: MutableRefObject<HTMLDivElement | null>;
    onAnchorClick: (href: string) => void;
}

const DATA_PREVENT_DEFAULT = "data-prevent-default";

/** Takes a reference of a DOM node, find all anchors attach event on click,
 *  if is the same host name opens the link
 */
export const usePreventAnchorDefault = ({ ref, onAnchorClick }: IPreventAnchorDefaultProps) => {
    const onClick = useCallback(
        (event: MouseEvent) => {
            if (event) {
                const link = event.currentTarget as HTMLAnchorElement;
                if (location.hostname !== link.hostname) {
                    event.preventDefault();
                    onAnchorClick(link.href);
                }
            }
        },
        [onAnchorClick]
    );

    useEffect(() => {
        if (!ref?.current) {
            return;
        }

        const anchors = Array.from(ref.current.getElementsByTagName("a"));

        anchors.forEach(anchor => {
            // in case of nesting, prevent attaching more events to anchor
            if (!anchor.getAttribute(DATA_PREVENT_DEFAULT)) {
                anchor.addEventListener("click", onClick);
                anchor.setAttribute(DATA_PREVENT_DEFAULT, "true");
            }
        });

        return () => {
            anchors.forEach(anchor => {
                anchor.removeEventListener("click", onClick);
                anchor.removeAttribute(DATA_PREVENT_DEFAULT);
            });
        };
    }, [onClick, ref]);
};
