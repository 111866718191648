import { useRef } from "react";
import { AspectRatio, Box, Text, BoxProps, useBoolean } from "@chakra-ui/react";
import { Article } from "src/components/base";
import { ResponsiveImage } from "src/components/base/responsive-image";
import { WfLink } from "src/components/base/wf-link";
import { IArticlePreview } from "src/components/common/article-preview/article-preview.types";
import { SimpleRichText } from "src/components/common/rich-text/simple-rich-text";
import { LeaveWikifolioDialog } from "src/components/modals/leave-wikifolio-dialog/leave-wikifolio-dialog";
import { useLeaveWikifolio } from "src/hooks/use-leave-wikifolio";
import { triggerCallbackOnMiddleClickEvent } from "src/utils/dom/mouse-util";

type TFontSizeTitle = "md" | "lg";
type TFontSizeContent = "sm" | "md";
type TSizeMapKey = "sm" | "md";
type TSizeMapValue = { fontSizeTitle: TFontSizeTitle; fontSizeContent: TFontSizeContent };

type TSizeMap = Record<TSizeMapKey, TSizeMapValue>;
const sizeMap: TSizeMap = {
    sm: {
        fontSizeTitle: "md",
        fontSizeContent: "sm",
    },
    md: {
        fontSizeTitle: "lg",
        fontSizeContent: "md",
    },
};

export interface IArticlePreviewProps extends BoxProps {
    articlePreview: IArticlePreview;
    onClick?: () => void;
    size?: TSizeMapKey;
    contentBoxProps?: BoxProps;
}

export const ArticlePreview = ({ articlePreview, onClick, size = "md", contentBoxProps, ...boxProps }: IArticlePreviewProps) => {
    const { image, siteName, title, description, url } = articlePreview;
    const ref = useRef<HTMLDivElement | null>(null);
    const { isOpen, onClose, onConfirm } = useLeaveWikifolio(ref);
    const [isImgLoadError, setImgLoadError] = useBoolean(false);
    const sizeProps = sizeMap[size];
    const imageUrl = typeof image === "string" ? image : image && image.url;

    const content = (
        <Article onClick={onClick} onAuxClick={triggerCallbackOnMiddleClickEvent(onClick)}>
            <Box display="contents" role="group">
                {imageUrl && !isImgLoadError && (
                    <AspectRatio mb={2} w="100%" ratio={16 / 9}>
                        <ResponsiveImage height="auto" width="100%" baseUrl={imageUrl} alt={title} onError={() => setImgLoadError.on()} />
                    </AspectRatio>
                )}
                <Box {...contentBoxProps}>
                    {siteName && (
                        <Text fontSize="sm" fontWeight="semibold" color="gray.500" mb={0.5}>
                            {siteName}
                        </Text>
                    )}
                    {title && (
                        <Text fontSize={sizeProps.fontSizeTitle} fontWeight="bold" mb={1} _groupHover={{ textDecor: "underline" }}>
                            {title}
                        </Text>
                    )}
                    {description && <SimpleRichText fontSize={sizeProps.fontSizeContent} text={description} />}
                </Box>
            </Box>
        </Article>
    );

    return (
        <Box ref={ref} {...boxProps}>
            <WfLink display="block" variant="unstyled" href={url} target="_blank" aria-label={title}>
                {content}
            </WfLink>
            <LeaveWikifolioDialog isOpen={isOpen} onConfirm={onConfirm} onClose={onClose} />
        </Box>
    );
};
