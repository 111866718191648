import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconSuccess: TIconComponent = props => {
    return (
        <Icon viewBox="0 0 555 560" {...props}>
            <path
                d="M142.991 251.69L130.211 290.72L206.111 344.12L254.961 402.3L278.251 443.72L320.901 429.16L291.191 370.21C291.191 370.21 267.571 332.74 228.231 303.47C188.891 274.2 142.991 251.69 142.991 251.69Z"
                fill="#95C11F"
            />
            <path
                d="M157.874 483.47L202.254 466.47L167.334 420.74L121.124 377.64L103.764 367.1L90.9336 407.93L129.104 440.45L157.874 483.47Z"
                fill="#95C11F"
            />
            <path
                d="M83.0525 510.37L346.453 420.45C365.643 413.9 375.332 392.56 367.602 373.81C355.302 343.97 333.953 305.07 297.003 268.75C261.783 234.13 224.572 213.83 195.662 201.92C176.942 194.21 155.642 203.95 149.102 223.1C119.132 310.9 89.1525 398.7 59.1725 486.5C54.1225 501.28 68.2625 515.41 83.0425 510.37H83.0525Z"
                stroke="#333333"
                strokeWidth="15.93"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M275.273 353.55C295.293 379.84 307.943 404.58 315.783 422.58"
                stroke="#333333"
                strokeWidth="15.93"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M142.992 249.8C168.062 260.72 207.542 281.56 245.782 319.8"
                stroke="#333333"
                strokeWidth="15.93"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M129.102 292.2C153.692 304.84 185.152 324.88 215.562 356.07C244.832 386.1 263.572 416.67 275.392 440.44"
                stroke="#333333"
                strokeWidth="15.93"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M106.262 364.7C123.822 376.29 142.802 391.18 161.382 410.25C179.162 428.49 193.052 446.93 203.872 463.92"
                stroke="#333333"
                strokeWidth="15.93"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M90.9336 410.61C103.154 419.88 115.744 430.75 128.154 443.48C140.534 456.18 151.024 468.98 159.914 481.32"
                stroke="#333333"
                strokeWidth="15.93"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M341.523 194.1L368.783 164.36C370.963 161.98 374.692 161.9 376.972 164.18L408.492 195.7C410.842 198.05 410.683 201.92 408.133 204.06L313.562 283.51"
                stroke="#333333"
                strokeWidth="15.93"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M285.633 255.07L311.213 227.16" stroke="#333333" strokeWidth="15.93" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M207.141 203.81C211.881 192.68 216.141 178.3 217.081 161.36C217.901 146.74 216.061 133.71 213.271 122.87C210.271 111.22 215.821 99.0799 226.821 94.1999L228.081 93.6399C241.801 87.5599 257.681 95.2599 261.471 109.78C266.091 127.47 267.961 145.88 266.941 164.13C265.711 186.24 260.211 208.16 250.911 228.29"
                stroke="#333333"
                strokeWidth="15.93"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M366.3 362.97C377.43 358.23 391.81 353.97 408.75 353.03C423.37 352.21 436.4 354.05 447.24 356.84C458.89 359.84 471.03 354.29 475.91 343.29L476.47 342.03C482.55 328.31 474.85 312.43 460.33 308.64C442.64 304.02 424.23 302.15 405.98 303.17C383.87 304.4 361.95 309.9 341.82 319.2"
                stroke="#333333"
                strokeWidth="15.93"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M342.701 93.9701L311.961 75.1601L338.361 18.5701L381.081 44.7101L342.701 93.9701Z"
                stroke="#333333"
                strokeWidth="15.93"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M64.4323 246.03L24.3524 250.2L8.40234 172.83L64.1024 167.03L64.4323 246.03Z"
                stroke="#333333"
                strokeWidth="15.93"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M301.602 497.52L327.922 471.2L382.012 515.04L345.432 551.62L301.602 497.52Z"
                stroke="#333333"
                strokeWidth="15.93"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M531.293 262.77L486.803 263.52L476.773 183.56L538.603 182.52L531.293 262.77Z"
                stroke="#333333"
                strokeWidth="15.93"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M509.544 438.93L547.004 446.83L517.944 471.76L522.004 509.83L489.324 489.89L454.374 505.52L463.234 468.27L437.574 439.86L475.724 436.78L494.824 403.59L509.544 438.93Z"
                stroke="#333333"
                strokeWidth="15.93"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M493.802 38.05L531.952 34.83L511.302 67.07L526.162 102.35L489.122 92.6799L460.162 117.72L457.902 79.5L425.152 59.69L460.802 45.74L469.512 8.45996L493.802 38.05Z"
                fill="#95C11F"
                stroke="#333333"
                strokeWidth="15.93"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M125.631 64.89L161.041 79.46L127.941 98.7L125.021 136.87L96.4914 111.33L59.2914 120.35L74.7614 85.34L54.6914 52.74L92.7714 56.63L117.571 27.47L125.631 64.89Z"
                stroke="#333333"
                strokeWidth="15.93"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Icon>
    );
};
