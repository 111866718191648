import { Component, ComponentType, ErrorInfo, PropsWithChildren, ReactNode } from "react";
import { Box } from "@chakra-ui/react";
import { Alert, AlertIcon, AlertTitle, AlertDescription } from "@chakra-ui/react";
import config from "src/config";
import { logger } from "src/utils/logger-util";

const UNHANDLED_ERROR_MESSAGE = "Unhandled error caught in ErrorBoundary";

interface IFallbackProps {
    /** Error object from caught exception */
    error: Error;
}

interface IErrorBoundaryProps {
    /** Callback invoked when an error is caught. Used if you want to have additional side-effects on error */
    onError?: (error: Error, info: { componentStack: string }) => void;
    /** Component that will be rendered if an error is caught when rendering children */
    FallbackComponent?: ComponentType<IFallbackProps>;
}

interface IErrorBoundaryState {
    error: Error | null;
}

/**
 * Error Boundary component -- wrap other components in an ErrorBoundary component
 * to prevent uncaught exceptions in child components from wrecking react page-wide.
 *
 * Optionally pass FallbackComponent for a replacement node in case of error.
 * If no error is caught, the children are rendered normally.
 */
export class ErrorBoundary extends Component<PropsWithChildren<IErrorBoundaryProps>, IErrorBoundaryState> {
    static getDerivedStateFromError(error: Error): IErrorBoundaryState {
        return { error };
    }

    state: IErrorBoundaryState = { error: null };

    componentDidCatch(error: Error, info: ErrorInfo): void {
        const { onError } = this.props;
        onError?.(error, info);

        logger.error({ message: UNHANDLED_ERROR_MESSAGE, error, errorInfo: info });
    }

    render(): ReactNode {
        const { error } = this.state;
        const { FallbackComponent, children } = this.props;

        if (error !== null) {
            if (FallbackComponent) {
                return <FallbackComponent error={error} />;
            }

            // if we caught an error in DEV then write out the stack trace.
            if (config.isDev) {
                return (
                    <Alert status="error">
                        <AlertIcon />
                        <Box flexFlow="column">
                            <AlertTitle mr={2}>Something went wrong!</AlertTitle>
                            <AlertDescription>{error.stack}</AlertDescription>
                        </Box>
                    </Alert>
                );
            }

            // returning empty react tree on PROD on error if FallBackComponent is not provided.
            return null;
        }

        return children;
    }
}
