import { MouseEvent, useCallback } from "react";
import { Progress, Radio, Text } from "@chakra-ui/react";
import { SpanFlex } from "src/components/base";
import { formatPercent } from "src/hooks/formatters/number-formatter";
import { IVotingAnswer } from "src/types/common";
import { useRouterLocale } from "src/utils/router/use-router-locale";
import { isNumber } from "src/utils/type-utils";
import { VOTING_MODULE_ANSWER_COUNT_TEST_ID, VOTING_MODULE_ANSWER_TEST_ID } from "./voting-answer.test-ids";

const COMMON_HEIGHT = [9, 7];

export interface IVotingAnswerProps extends IVotingAnswer {
    isDisabled: boolean;
    isChecked: boolean;
    onClick: () => void;
}

export const VotingAnswer = ({ text, count, onClick, isDisabled, isChecked }: IVotingAnswerProps) => {
    const { language } = useRouterLocale();

    const countPercents = typeof count === "undefined" ? undefined : count * 100;

    const handleClick = useCallback(
        (e: MouseEvent<HTMLElement>) => {
            // We have to check if the origin of the event is the input because it triggers the unwanted second call
            if (!isDisabled && !isChecked && (e.target as HTMLElement).nodeName !== "INPUT") {
                onClick();
            }
        },
        [isChecked, isDisabled, onClick]
    );

    return (
        <Progress
            onClick={handleClick}
            value={countPercents}
            border="1px"
            borderColor="gray.200"
            backgroundColor="transparent"
            rounded="8px"
            cursor={isDisabled ? "not-allowed" : "pointer"}
            width="100%"
            h={COMMON_HEIGHT}
            data-test-id={VOTING_MODULE_ANSWER_TEST_ID}
        >
            <Radio position="absolute" top={0} left={[2, 3]} isDisabled={isDisabled} isChecked={isChecked} size="md">
                <Text color="gray.800" h={COMMON_HEIGHT} display="flex" alignItems="center" pr={10}>
                    {text}
                </Text>
            </Radio>
            {isNumber(count) && (
                <SpanFlex
                    position="absolute"
                    align="center"
                    h={COMMON_HEIGHT}
                    top={0}
                    right={3}
                    fontWeight="bold"
                    data-test-id={VOTING_MODULE_ANSWER_COUNT_TEST_ID}
                >
                    {formatPercent({ value: count, language })}
                </SpanFlex>
            )}
        </Progress>
    );
};
