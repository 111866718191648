import { IVoteSummaryItem } from "src/components/common/voting/types";

export const calculateProportionsOfCounts = (
    answers: ReadonlyArray<string>,
    voteSummary: ReadonlyArray<IVoteSummaryItem> | null
): ReadonlyArray<number> => {
    const sum = voteSummary?.reduce((partialSummary, voteSummaryItem) => partialSummary + (voteSummaryItem.count ?? 0), 0) ?? 0;

    return answers.map((answer, index) => {
        if (sum === 0) {
            return 0;
        }

        const foundVoteSummaryItem = voteSummary?.find(item => Number(item.choice) === index);

        return (foundVoteSummaryItem?.count ?? 0) / sum;
    });
};
