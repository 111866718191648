import { IVotingDetailResult } from "src/components/common/voting/types";
import { API_VOTE_USER_URL } from "src/consts/urls";
import { useMutation } from "src/hooks/react-query/use-mutation";
import { IErrorResponse } from "./types";

export interface IVoteUserRequest {
    voteTemplateId: string;
    value: number;
    placement: number;
}

export const useVoteUser = () => {
    const { mutate } = useMutation<IVotingDetailResult, IErrorResponse, IVoteUserRequest>(API_VOTE_USER_URL);

    return mutate;
};
