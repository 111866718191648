import { useCallback, useEffect, useMemo, useState } from "react";
import { IVoteSummaryItem } from "src/components/common/voting/types";
import { calculateProportionsOfCounts } from "src/components/common/voting/utils/calculate-proportions";
import { VotingAnswer } from "src/components/common/voting/voting-answer";
import { isServerSide } from "src/utils/common-util";

interface IVotingAnswersBlockProps {
    answers: ReadonlyArray<string>;
    selectedAnswerIndex: string | null;
    voteSummary: ReadonlyArray<IVoteSummaryItem> | null;
    onVoteChange: (userChoice: number) => void;
    hasVotingEnded: boolean;
}

export const VotingAnswersBlock = ({ answers, selectedAnswerIndex, voteSummary, onVoteChange, hasVotingEnded }: IVotingAnswersBlockProps) => {
    const defaultCountPercentages = Array(answers.length).fill(0);
    const [visiblePercentages, setVisiblePercentages] = useState<ReadonlyArray<number>>(defaultCountPercentages);

    const calculatedPercentages = useMemo(() => calculateProportionsOfCounts(answers, voteSummary), [answers, voteSummary]);

    const getAnswerPercentageCount = useCallback(
        (index: number) => {
            if (selectedAnswerIndex || hasVotingEnded) {
                return isServerSide() ? calculatedPercentages[index] : visiblePercentages[index];
            }
        },
        [hasVotingEnded, selectedAnswerIndex, calculatedPercentages, visiblePercentages]
    );

    useEffect(() => {
        // to guarantee the re-rendering that provides the animation
        setTimeout(() => setVisiblePercentages(calculatedPercentages), 0);
    }, [calculatedPercentages, voteSummary]);

    return (
        <>
            {answers.map((answer, index) => (
                <VotingAnswer
                    key={index}
                    text={answer}
                    count={getAnswerPercentageCount(index)}
                    isDisabled={hasVotingEnded}
                    isChecked={selectedAnswerIndex !== null && Number(selectedAnswerIndex) === index}
                    onClick={() => onVoteChange(index)}
                />
            ))}
        </>
    );
};
