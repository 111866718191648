import { Box, BoxProps } from "@chakra-ui/react";
import { SPACE_UNIT_SIZE_PX } from "src/theme";

export interface IFilledCircleProps extends BoxProps {
    size: number;
    color: string;
}

export const FilledCircle = ({ size, color, ...props }: IFilledCircleProps) => (
    <Box w={size} h={size} rounded={SPACE_UNIT_SIZE_PX * size} bg={color} {...props} />
);
