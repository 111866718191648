import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds, isAfter } from "date-fns";
import { useTranslation } from "next-i18next";

export const useVotingStatus = (votingUntilString: string, currentDateString: string | undefined) => {
    const { t } = useTranslation("voting-module");

    const votingUntilDate = new Date(votingUntilString);
    const currentDate = currentDateString ? new Date(currentDateString) : new Date();

    const getMinutesText = () => {
        const remainingMinutes = differenceInMinutes(votingUntilDate, currentDate);

        if (remainingMinutes > 1) {
            return t("several-minutes-left", { 0: remainingMinutes });
        }

        if (remainingMinutes >= 0) {
            const remainingSeconds = differenceInSeconds(votingUntilDate, currentDate);
            if (remainingSeconds > 0) {
                return t("one-minute-left");
            }
        }

        return t("voting-ended");
    };

    const getHoursText = () => {
        const remainingHours = differenceInHours(votingUntilDate, currentDate);

        if (remainingHours > 1) {
            return t("several-hours-left", { 0: remainingHours });
        }

        if (remainingHours === 1) {
            return t("one-hour-left");
        }

        return getMinutesText();
    };

    return {
        getText: () => {
            const remainingDays = differenceInDays(votingUntilDate, currentDate);

            if (remainingDays > 1) {
                return t("several-days-left", { 0: remainingDays });
            }

            if (remainingDays === 1) {
                return t("one-day-left");
            }

            return getHoursText();
        },
        hasEnded: isAfter(currentDate, votingUntilDate),
    };
};
