import { useTranslation } from "next-i18next";
import { WfAlertDialog } from "src/components/modals/wf-alert-dialog/wf-alert-dialog";

interface ILeaveWikifolioDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

export const LeaveWikifolioDialog = ({ isOpen, onClose, onConfirm }: ILeaveWikifolioDialogProps) => {
    const { t } = useTranslation("common");

    return (
        <WfAlertDialog
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={onConfirm}
            confirmButtonText={t("accept")}
            header={t("leaving-wf-header")}
            body={t("leaving-wf-body")}
        />
    );
};
